const $ = require('jquery')
const { qc } = window.cmp

window.jQuery = window.$ = $
$.fn.forEach =
  $.fn.forEach ||
  function (f) {
    return Array.from(this).forEach(f)
  }

// sets up the correct event handler arguments so we can have qc() and jquery events working together
const wrapHandler = handler => {
  const result = function (e, ...args) {
    //console.log('>>>>>>>> ', e.type, args, e.owArgs || e.originalEvent?.owArgs || [])
    args = [...args, ...(e.owArgs || e.originalEvent?.owArgs || [])]
    //console.log('>>>>>>>> ', ...args)
    return handler.call(this, e, ...args)
  }
  result.innerHandler = handler
  handler.wrappedHandler = result
  return result
}

$.fn._ow_on = $.fn.on
$.fn.on = function (...a) {
  if (typeof a[3] === 'function') a[3] = wrapHandler(a[3])
  if (typeof a[2] === 'function') a[2] = wrapHandler(a[2])
  if (typeof a[1] === 'function') a[1] = wrapHandler(a[1])
  return $.fn._ow_on.call(this, ...a)
}

$.fn._ow_one = $.fn.one
$.fn.one = function (...a) {
  if (typeof a[3] === 'function') a[3] = wrapHandler(a[3])
  if (typeof a[2] === 'function') a[2] = wrapHandler(a[2])
  if (typeof a[1] === 'function') a[1] = wrapHandler(a[1])
  return $.fn._ow_one.call(this, ...a)
}

$.fn._ow_off = $.fn.off
$.fn.off = function (...a) {
  if (typeof a[3] === 'function') a[3] = a[3].wrappedHandler || a[3]
  if (typeof a[2] === 'function') a[2] = a[2].wrappedHandler || a[2]
  if (typeof a[1] === 'function') a[1] = a[1].wrappedHandler || a[1]
  return $.fn._ow_off.call(this, ...a)
}

/**
 * jQuery trigger override
 *
 * Why?  we are currently (Jue 2022) looking to convert all views from EJS/kendo/jquery to qc() QuickCmp
 * on the way towards ow6/Cmp.
 *
 * To make the code conversion work, we need the events to fire using the native event engine.
 *
 * if we call $(el).trigger('ow-change') ow-change does not trigger native events and therefore
 *  the handler in qcObject.on('ow-change', handler) does not get triggered
 *
 * so we can't change the $(el).on() to qc(el).on().  This override ensures it does.
 *
 */

const jqueryTrigger = $.fn.trigger

// // (event, data, elem, onlyHandlers)
$.fn.trigger = function (et, ...args) {
  const etIsEventObject = typeof et === 'object'

  // we exclude blur and focus because of jquery and kendo data change in ow4 grids
  // we use $(control).trigger('focus') before moving to the next row on arrow down.
  // For some reason it doesn't work if we trigger natively so we need to pass thru to jQuery.
  // For type and tab out we check what constructor was used for
  if (etIsEventObject)
    //  || nativeEventTypes.indexOf(et) !== -1)
    return jqueryTrigger.call(this, et, ...args)

  const [args0 = []] = args
  const qcArgs = args[0] && Array.isArray(args[0]) ? args0 : []

  this.forEach(el => {
    // sometimes the object isn't an HTMLElement - see strange use of ow5.dc restoreData!?
    if (!el.dispatchEvent) return jqueryTrigger.call($(this), et, ...args)

    return qc(el).trigger(et, ...qcArgs)
  })

  return this
}

module.exports = window.$
