const $ = require('jquery')

window.jQuery = window.$ = $
$.fn.forEach =
  $.fn.forEach ||
  function (f) {
    return Array.from(this).forEach(f)
  }

// sets up the correct event handler arguments so we can have qc() and jquery events working together
const wrapHandler = handler => {
  const result = function (e, ...args) {
    //console.log('>>>>>>>> ', e.type, args, e.owArgs || e.originalEvent?.owArgs || [])
    args = [...args, ...(e.owArgs || e.originalEvent?.owArgs || [])]
    //console.log('>>>>>>>> ', ...args)
    return handler.call(this, e, ...args)
  }
  result.innerHandler = handler
  handler.wrappedHandler = result
  return result
}

$.fn._ow_on = $.fn.on
$.fn.on = function (...a) {
  if (typeof a[3] === 'function') a[3] = wrapHandler(a[3])
  if (typeof a[2] === 'function') a[2] = wrapHandler(a[2])
  if (typeof a[1] === 'function') a[1] = wrapHandler(a[1])
  return $.fn._ow_on.call(this, ...a)
}

$.fn._ow_one = $.fn.one
$.fn.one = function (...a) {
  if (typeof a[3] === 'function') a[3] = wrapHandler(a[3])
  if (typeof a[2] === 'function') a[2] = wrapHandler(a[2])
  if (typeof a[1] === 'function') a[1] = wrapHandler(a[1])
  return $.fn._ow_one.call(this, ...a)
}

$.fn._ow_off = $.fn.off
$.fn.off = function (...a) {
  if (typeof a[3] === 'function') a[3] = a[3].wrappedHandler || a[3]
  if (typeof a[2] === 'function') a[2] = a[2].wrappedHandler || a[2]
  if (typeof a[1] === 'function') a[1] = a[1].wrappedHandler || a[1]
  return $.fn._ow_off.call(this, ...a)
}

module.exports = $
